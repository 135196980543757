<template>
  <div>
    <NCard
      title="课程大纲"
      size="small"
      :segmented="{ content: 'hard' }"
    >
      <AddLocalFile
        ref="addLocalFileRef"
        style="width: 350px;"
        :max-num="1"
        accept=".jpg,.jpeg,.png,.gif,.pdf,.mp4,.mp3"
        :max-bytes-size="300 * 1024 * 1024"
        accept-size-txt="文件大小不超过 300MB"
        :init-list="initUploadList"
      />
    </NCard>
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  import AddLocalFile from '@/components/AddLocalFile/AddLocalFile.vue';

  const addLocalFileRef = ref(null);
  const initUploadList = ref([]);

  defineExpose({
    initData: ({ id, file_name: name, file_size, file_url } = {}) => {
      initUploadList.value = [
        {
          id,
          savedId: id,
          name,
          status: 'pending',
          file: {
            size: Number(file_size)
          },
          saved: true,
          fileUrl: file_url
        }
      ]
    },
    validate: () => new Promise(resolve => {
      if (addLocalFileRef.value.getFileList().length > 0) {
        resolve();
      } else {
        resolve('请上传课程附件');
      }
    }),
    getFiles: () => addLocalFileRef.value.getFileList()
  });
</script>